import React from 'react';
import { Container } from '../Container';
const FooterBase = () => {
  return (
    <footer className="pt-4 pb-6 text-gray-600">
      <Container>Felicette</Container>
    </footer>
  );
};
export const Footer = React.memo(FooterBase);
