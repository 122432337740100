import React from 'react';
import { Container } from '../Container';
import { HeaderUser } from './User';
const HeaderBase = () => {
  return (
    <header className="bg-main-900 pt-1 pb-1 max-w-none w-full box-border">
      <Container>
        <div className="flex gap-x-2 justify-between items-center">
          <h1 className="text-xl">
            Felicette <small className="text-secundary-500">Admin</small>
          </h1>
          <HeaderUser />
        </div>
      </Container>
    </header>
  );
};
export const Header = React.memo(HeaderBase);
