import React from 'react';
const InputBase: React.FC<React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>> = ({ className, ...props }) => {
  return (
    <input
      {...props}
      className={`border-main-500 hover:border-main-300 focus:border-main-100 border-solid border bg-transparent outline-none px-2 py-1 placeholder-main-400 ${className}`}
    />
  );
};
export const Input = React.memo(InputBase);
