import React from 'react';
import { useState } from 'react';
import { useCallback } from 'react';
import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import firebase from 'gatsby-plugin-firebase';
import { Spinner } from '../../components/Spinner';
import { AiOutlineAlert } from 'react-icons/ai';
const LoginFormBase = () => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [sending, setSending] = useState(false);
  const [error, setError] = useState(false);
  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      setSending(true);
      setError(false);
      event.preventDefault();
      console.log({ login, password });
      try {
        await firebase.auth().signInWithEmailAndPassword(login, password);
      } catch (e) {
        console.error(e);
        setError(true);
      }
      setSending(false);
    },
    [login, password, setSending, setError],
  );
  return (
    <form onSubmit={handleSubmit}>
      <section className="grid sm:grid-cols-2 md:grid-cols-3 gap-2">
        <Input
          placeholder="login"
          type="email"
          value={login}
          onChange={(e) => {
            setLogin(e.target.value);
          }}
        />
        <Input
          placeholder="password"
          type="password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        <section className="sm:col-span-2 md:col-span-1">
          <Button as="button" className="bg-main-500 w-full hover:bg-main-600">
            {sending ? <Spinner /> : 'send'}
          </Button>
        </section>
      </section>
      {error ? (
        <div className="bg-red-900 flex px-2 py-2 items-center w-auto mx-auto justify-center mt-2">
          <AiOutlineAlert className="mr-2" />
          <span>ops! try again</span>
        </div>
      ) : null}
    </form>
  );
};

export const LoginForm = React.memo(LoginFormBase);
