import React from 'react';
import { useMemo } from 'react';
import { Spinner } from '../../components/Spinner';
import { AuthWrapper } from '../../context/Auth/Wrapper';
import { useAuth } from '../../hooks/useAuth';
import { Container } from './Container';
import { Footer } from './Footer';
import { Header } from './Header';
import { Helmet } from 'react-helmet';
import './styles.css';
import { LoginForm } from '../LoginForm';
import { Nav } from './Nav';
type LayoutProps = {
  children: React.ReactNode;
  contained?: boolean;
  title: string;
  authed?: boolean;
};
const LayoutBase: React.FC<LayoutProps> = ({
  children,
  title,
  contained = true,
  authed = true,
}) => {
  const auth = useAuth();
  const loading = useMemo(() => auth === null, [auth]);
  const isAllowed = useMemo(() => {
    if (auth == null) {
      return false;
    }
    if (authed && auth) {
      return true;
    } else if (!authed) {
      return true;
    }
    return false;
  }, [auth, authed]);
  return loading ? (
    <div className="relative w-100" style={{ height: '100vh' }}>
      <div
        style={{
          position: 'absolute',
          transform: 'translate(-50%,-50%)',
          left: '50%',
          top: '50%',
        }}
      >
        <Spinner alternative />
      </div>
    </div>
  ) : (
    <>
      <Helmet title={title} titleTemplate={`%s | Felicette admin`} />
      <Header />
      <Nav />
      {contained ? (
        <Container className="pt-2 pb-2">
          {isAllowed ? (
            <>
              <h1 className="text-2xl mb-2">{title}</h1>
              {children}
            </>
          ) : (
            <LoginForm />
          )}
        </Container>
      ) : isAllowed ? (
        children
      ) : (
        <LoginForm />
      )}
      <Footer />
    </>
  );
};
export const Layout: React.FC<LayoutProps> = React.memo((props) => {
  return (
    <AuthWrapper>
      <LayoutBase {...props} />
    </AuthWrapper>
  );
});

export const withLayout = (
  layoutProps: Omit<LayoutProps, 'children'>,
  children,
) => <Layout {...layoutProps}>{children}</Layout>;
