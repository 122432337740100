import React, { useCallback, useState } from 'react';
import { useAuth } from '../../../../hooks/useAuth';
import { AiOutlineUser, AiOutlineLogout, AiOutlineLogin } from 'react-icons/ai';
import { Button } from '../../../../components/Button';
import firebase from 'gatsby-plugin-firebase';
import { Spinner } from '../../../../components/Spinner';
const HeaderUserBase = () => {
  const auth = useAuth();
  const [singingOut, setSingingOut] = useState(false);
  const handleLogout = useCallback(() => {
    setSingingOut(true);
    firebase
      .auth()
      .signOut()
      .then(() => {
        setSingingOut(false);
      })
      .catch(() => {
        setSingingOut(false);
      });
  }, [setSingingOut]);
  return (
    <div>
      {auth ? (
        <>
          <div className="flex gap-x-2 text-4 items-center">
            <span>
              <AiOutlineUser />
            </span>
            <span className="truncate hidden sm:block">{auth.email}</span>
            <Button
              onClick={handleLogout}
              className="bg-alternative-500 hover:bg-alternative-900 text-gray-900 fill-current cursor-pointer"
            >
              {singingOut ? <Spinner /> : <AiOutlineLogout />}
            </Button>
          </div>
        </>
      ) : (
        <AiOutlineLogin />
      )}
    </div>
  );
};
export const HeaderUser = React.memo(HeaderUserBase);
