import React from 'react';
type AElProps = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
> & {
  as?: string;
};
type BtnElProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & { as: 'button' };
type ButtonProps = AElProps | BtnElProps;
const ButtonBase: React.FC<ButtonProps> = ({
  as = 'a',
  className,
  ...props
}) => {
  const El = as as any;
  return (
    <El {...props} className={`uppercase text-center py-1 px-1 ${className}`} />
  );
};
export const Button = React.memo(ButtonBase);
