import React from 'react';
import { AiOutlineLoading, AiOutlineLoading3Quarters } from 'react-icons/ai';
type SpinnerProps = {
  alternative?: boolean;
};
const SpinnerBase: React.FC<SpinnerProps> = ({ alternative = false }) => {
  return (
    <>
      {alternative ? (
        <AiOutlineLoading3Quarters
          className="inline-block animate-spin ease-in-out text-alternative-500 fill-current rotate-330"
          aria-label="loading..."
        />
      ) : (
        <AiOutlineLoading
          className="inline-block animate-spin ease-in-out text-secundary-500 fill-current"
          style={{
            animationDirection: 'reverse',
          }}
          aria-label="loading..."
        />
      )}
    </>
  );
};
export const Spinner = React.memo(SpinnerBase);
