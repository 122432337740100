import React from 'react';
import { Link } from 'gatsby';
import { Container } from '../Container';

const NavItem = React.memo((props: { to: string; [key: string]: any }) => (
  <Link
    className="inline-block px-2 py-2 rounded-bl-lg rounded-br-lg hover:bg-main-900"
    activeClassName="bg-gray-900 hover:bg-gray-900 text-gray-100 rounded-tl-lg rounded-tr-lg"
    {...props}
  />
));
const NavBase = () => {
  return (
    <nav className="bg-main-900 box-border overflow-x-auto overflow-y-hidden whitespace-no-wrap bg-gradient-to-t from-gray-900 to-main-900">
      <Container>
        <NavItem to="/">Home</NavItem>
        <NavItem to="/users">Users</NavItem>
        <NavItem to="/families">Families</NavItem>
        <NavItem to="/species">Species</NavItem>
        <NavItem to="/products">Products</NavItem>
        <NavItem to="/plans">Plans</NavItem>
      </Container>
    </nav>
  );
};
export const Nav = React.memo(NavBase);
