import { User } from 'firebase';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { AuthContext } from './Context';
import firebase from 'gatsby-plugin-firebase';

type AuthWrapperProps = {
  children: React.ReactNode;
};
const AuthWrapperBase: React.FC<AuthWrapperProps> = (props) => {
  const [currentUser, setCurrentUser] = useState<null | false | User>(null);
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((authState) => {
      if (!authState) {
        setCurrentUser(false);
      } else {
        setCurrentUser(authState);
      }
    });
    return () => {
      unsubscribe();
    };
  }, [setCurrentUser]);
  return <AuthContext.Provider value={currentUser} {...props} />;
};

export const AuthWrapper = React.memo(AuthWrapperBase);
