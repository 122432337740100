import React from 'react';
type ContainerProps = {
  className?: string;
  children: React.ReactNode;
};
const ContainerBase: React.FC<ContainerProps> = ({ className, ...props }) => {
  return (
    <div className={`container mx-auto pl-2 pr-2 ${className}`} {...props} />
  );
};
export const Container = React.memo(ContainerBase);
